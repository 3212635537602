import Service from '@/services/request';
import { AxiosResponse, RawAxiosRequestHeaders } from 'axios';

export class CarrierService {
  private baseEndpoint = 'carrier';

  create = async (data: any): Promise<AxiosResponse<any, any>> => {
    Service.service.defaults.headers['Content-Type'] = 'multipart/form-data';
    const endpoint = this.baseEndpoint;

    return Service.post(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  update = async (id: string, data: any): Promise<AxiosResponse<any, any>> => {
    Service.service.defaults.headers['Content-Type'] = 'multipart/form-data';
    const endpoint = `${this.baseEndpoint}/${id}`;

    return Service.put(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  getAll = async (
    requestParams?: string,
    headers?: Partial<RawAxiosRequestHeaders>
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}?${requestParams || 'limit=1000 '}`;
    return Service.get(endpoint, headers) as Promise<AxiosResponse<any, any>>;
  };

  getPlan = async (
    id: number,
    headers?: Partial<RawAxiosRequestHeaders>
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/${id}/plans`;
    return Service.get(endpoint, headers) as Promise<AxiosResponse<any, any>>;
  };

  getAllPaginated = async (
    requestParams: string,
    headers?: Partial<RawAxiosRequestHeaders>
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/list?${requestParams}`;
    return Service.get(endpoint, headers) as Promise<AxiosResponse<any, any>>;
  };

  getById = async (
    id: number,
    headers?: Partial<RawAxiosRequestHeaders>
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/${id}`;
    return Service.get(endpoint, headers) as Promise<AxiosResponse<any, any>>;
  };
}
