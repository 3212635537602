import Service from '@/services/request';
import { AxiosResponse } from 'axios';

export class DocumentService {
  retrieveFaqs = async (
    requestParams: string
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `faq?${requestParams}`;

    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  retrieveFaq = async (id: number): Promise<AxiosResponse<any, any>> => {
    const endpoint = `faq/${id}`;

    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  createFaq = async (data: any): Promise<AxiosResponse<any, any>> => {
    const endpoint = 'faq';

    return Service.post(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  updateFaq = async (
    id: number,
    data: any
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `faq/${id}`;

    return Service.put(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  deleteFaq = async (idList: number[]): Promise<AxiosResponse<any, any>> => {
    const endpoint = 'faq';

    const payload = {
      id_list: idList,
    };

    return Service.delete(endpoint, payload) as Promise<
      AxiosResponse<any, any>
    >;
  };

  retrievePolicies = async (): Promise<AxiosResponse<any, any>> => {
    const endpoint = 'policy';

    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  retrievePolicy = async (id: number): Promise<AxiosResponse<any, any>> => {
    const endpoint = `policy/${id}`;

    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  createPolicy = async (data: any): Promise<AxiosResponse<any, any>> => {
    const endpoint = 'policy';

    return Service.post(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  updatePolicy = async (
    id: number,
    data: any
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `policy/${id}`;

    return Service.put(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  deletePolicy = async (idList: number[]): Promise<AxiosResponse<any, any>> => {
    const endpoint = 'policy';

    const payload = {
      id_list: idList,
    };

    return Service.delete(endpoint, payload) as Promise<
      AxiosResponse<any, any>
    >;
  };

  pushPolicyNotification = async (): Promise<AxiosResponse<any, any>> => {
    const endpoint = 'policy/silent-push';

    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  retrieveTerms = async (): Promise<AxiosResponse<any, any>> => {
    const endpoint = 'terms';

    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  retrieveTerm = async (id: number): Promise<AxiosResponse<any, any>> => {
    const endpoint = `terms/${id}`;

    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  createTerm = async (data: any): Promise<AxiosResponse<any, any>> => {
    const endpoint = 'terms';

    return Service.post(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  updateTerm = async (
    id: number,
    data: any
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `terms/${id}`;

    return Service.put(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  deleteTerm = async (idList: number[]): Promise<AxiosResponse<any, any>> => {
    const endpoint = 'terms';

    const payload = {
      id_list: idList,
    };

    return Service.delete(endpoint, payload) as Promise<
      AxiosResponse<any, any>
    >;
  };

  pushTermNotification = async (): Promise<AxiosResponse<any, any>> => {
    const endpoint = 'terms/silent-push';

    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };
}
